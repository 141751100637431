import "./App.css"

import Home from "./screens/home.jsx"

import Projects from "./screens/projects.jsx"

import Resume from "./screens/resume.jsx"

import Contacts from "./screens/contacts.jsx"

import Footer from "./screens/footer.jsx"

import Titlebar from "./screens/titlebar.jsx"


const App = props => {
  return (
    <div className="App">
        <Titlebar/>
        <Home />
        <Resume />
        <Projects />
        <Contacts />
        <Footer/>

    </div>
  )
}

export default App
