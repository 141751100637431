import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./titlebar.css";

function TitleBar() {
  // State to hold dynamic values for duration and offset
  const [linkSettings, setLinkSettings] = useState({
    duration: 500,
    offset: -50,
  });

  // Function to update link settings based on screen width
  const updateLinkSettings = () => {
    const screenWidth = window.innerWidth;
    // Example conditions - adjust these based on your requirements
    if (screenWidth > 601) {
      setLinkSettings({
        duration: 500,
        offset: 50,
      });
    } else {
      setLinkSettings({
        duration: 500,
        offset: -60,
      });
    }
  };

  // useEffect to add event listener on mount and cleanup on unmount
  useEffect(() => {
    window.addEventListener("resize", updateLinkSettings);
    // Set initial values based on current screen width
    updateLinkSettings();
    return () => {
      window.removeEventListener("resize", updateLinkSettings);
    };
  }, []);
  
	return (
		<>
		<nav className="mynavbar fixed-top">
			<div className="navbar-container">
				<div className="navbar-nav">
					<li className="nav-item">
						<Link
						className="mynavlink"
						to="home" // Replace with the target section's ID
						smooth={true}
						activeClass="active"
						duration={linkSettings.duration}
				offset={linkSettings.offset}
						>
						About
						</Link>
					</li>
					<li className="nav-item">
						<Link
						className="mynavlink"
						to="resume" // Replace with the target section's ID
						smooth={true}
						activeClass="active"
						duration={linkSettings.duration}
				offset={linkSettings.offset}
						>
						Experiences
						</Link>
					</li>
					<li className="nav-item">
						<Link
						className="mynavlink"
						to="projects" // Replace with the target section's ID
						smooth={true}
						activeClass="active"
						duration={linkSettings.duration}
				offset={linkSettings.offset}
						>
						Projects
						</Link>
					</li>
					<li className="nav-item">
						<Link
						className="mynavlink"
						to="contacts" // Replace with the target section's ID
						smooth={true}
						activeClass="active"
						duration={linkSettings.duration}
						offset={linkSettings.offset}
						>
						Contact
						</Link>
					</li>
				</div>
			</div>
	  </nav>
	  </>

	);
}

export default TitleBar;