import React,{ useState } from "react";
import { Modal } from "react-bootstrap";

import "./projects.css";

const MyCard =({title,description, image,youtubelink,githublink})=>{
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (event) => {
event.stopPropagation(); // Prevent click from bubbling to elements behind the modal
    setShowModal(true);
    };
  
    const handleCloseModal = (event) => {
        event.stopPropagation();
      setShowModal(false);
    };
  
    return(
    <div className="mycard" onClick={handleShowModal}>
    <div className="ProjectPic">
        <img src={image} className="card-img-top" alt="..."/>
    </div>
    <div className="card-body">
      <h5 className="card-title">{title}</h5>
      <p>{description.substring(0, 100)}...Read More</p>
    </div>
        <ProjectModal
            show={showModal}
            onHide={handleCloseModal}
            project={{ title, description, image,youtubelink,githublink }}
        />
  </div>);
}

const ProjectModal = ({ show, onHide, project }) => {
    console.log(project.githubink); 

    return (
        <Modal Modal show={show} 
        onHide={(e) => {e && e.stopPropagation(); onHide();}}
        centered onClick={(e) => e.stopPropagation()}
        >
        <Modal.Header closeButton onClick={onHide} style={{ backgroundColor: "#cbcbcb" }}>
          <Modal.Title>{project.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#cbcbcb" }}>
          <div className="modal-scrollable-content">
                {project.description.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
            ))}
           <div className="ratio ratio-16x9">
                {project.youtubelink ? (
                    <iframe src={project.youtubelink} title="YouTube video" allowFullScreen></iframe>
                ) : (
                    <div className="modalImage">
                    <img src={project.image} alt="..." />
                    </div>
            )}
                </div>
                {project.githublink && (
                <div className="Github">
                <a href={project.githublink}>
                    <img src="/icons8-github.svg" alt="GitHub Repository"/>
                </a>
                </div>
                )}
            <button onClick={onHide} className="mybtn btn-primary">
             Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

export default function Projects() {
    const projects=[
    {
        id: 1,
        title: "Adaptive Traffic Signal control System",
        description: `Enhancing pedestrian safety and accessibility in smart cities is a crucial goal for modern transportation innovations. However, current pedestrian signal controls often lack optimization for individuals with disabilities and older adults, highlighting the urgent need for adaptive and equitable solutions.
        
        This research aims to address this issue by developing a digital twin, utilizing the 2D traffic simulator SUMO and the 3D simulator CARLA, alongside a vision-based adaptive signal control system. The primary focus is on enhancing pedestrian safety, particularly for those utilizing mobility aids.
        
        To achieve this, the research project aims to build a beta version of a digital twin, enabling real-time vehicle and pedestrian input exchange between SUMO and the CARLA simulator. This integration facilitates dynamic and precise simulations of real-world traffic scenarios involving both vehicles and pedestrians.
        
        Additionally, the research implements an object detection model powered by cutting-edge computer vision techniques within CARLA, ensuring efficient pedestrian detection, with a specific emphasis on individuals using mobility aids. The insights from the detection model play a crucial role in shaping the Python-based adaptive signal system to cater to the unique needs of pedestrians with mobility aids.
        
        The project demonstrates the effectiveness of the adaptive control system in the digital twin model, promoting equitable crossing times for pedestrians with mobility aids, and ultimately enhancing safety and accessibility for marginalized communities.`,
        image: "./project1.png",
        youtubelink: "https://www.youtube.com/embed/T2uW_9n7X10?si=XJ6_zpcY1xiKPEw_",
        githublink: ""
    },
    {
        id: 2,
        title: "Pedestrian Intention Prediction",
        description: `Cooperative Driving has become one of the new research frontiers in the field of connected and autonomous vehicle (CAV) research. Pedestrian safety remains to be one of the critical issues in Cooperative Driving and CAV applications. This is because it has been a challenging task for connected and autonomous vehicles to estimate when pedestrians will cross streets since pedestrians can move in different directions, suddenly change motion, be occluded by a variety of obstacles, and be distracted while talking to other pedestrians or typing on a mobile phone. Moreover, their decisions can also be affected by several factors. 
        
        Intention prediction algorithms can provide essential information for warning systems or autonomous driving systems to avoid pedestrian-vehicle incidents.Existing methods for pedestrians’ intention prediction include trajectory inference and pose inference. 
        
        The goal of this project was to explore existing methods of intention predictions, implement State of Art methods to develop a deep learning-based intention prediction algorithm to improve vehicle and vulnerable road user safety in urban areas. Real- world videos recorded by CCTV cameras on different streets of New York City will be used to extract vehicle and pedestrian behaviors to be used in the development of intention prediction algorithms.`,
        image: "./project2.png",
        youtubelink: "https://www.youtube.com/embed/ECDpTJ1XXxg?si=JEjOMYb9ejg8L4sM",
        githublink: ""
    },
    
    {
        id: 3,
        title: "Study Buddy Mobile App",
        description:  `Study Buddy is an application designed to help students connect and collaborate with others for exam preparation. By finding study partners who share the same course and exam schedule, students can form study groups and enhance their learning experience. 

        The core mission of Study Buddy was to address the significant challenge of student isolation during exam preparation, a common issue in academic settings. By leveraging technology to connect students with similar academic interests and schedules, the app aimed to simplify the process of forming study groups. This not only made study sessions more accessible but also enriched the learning experience by promoting an exchange of ideas and peer support. The app's design and functionality were guided by the principle of enhancing academic collaboration, thereby aiming to improve students' confidence and mastery over their subjects.
        
        Studdy Buddy app was a collaborative initiative at New York University Tandon School of Engineering that was developed using the Scrum framework, an agile development methodology that enabled our team to efficiently manage tasks and adapt to changes swiftly. This approach facilitated a dynamic and responsive development process, allowing for continuous feedback and iterations.As a Full-stack Software Engineer, I played a crucial role in this process, employing a stack comprising JavaScript, React.js, Express.js, and MongoDB to construct a comprehensive, full-stack mobile web application. 
        `,
        image: "./project3.png",
        youtubelink: "",
        githublink: "https://github.com/Kelo2000/StudyBuddy"
        
    },

    {
        id: 4,
        title: "Autonomous Line Following Robot",
        description:  `The Autonomous Line Following Robot Project demonstrates an  application of robotics and computer vision to navigate complex field autonomously. Inspired by the 2018 RoboCupJunior Rescue Line Rules, the project's goal was to program NVIDIA Jetbot AI robot to follow a pre-defined black line path while overcoming obstacles such as intersections, green squares for decision-making, and red lines signaling stops. This project highlights the integration of line following algorithms and real-time image processing for accurate navigation and responsive decision-making.

        At the core of the robot's navigational abilities is the use of computer vision and image processing techniques. By using algorithms for black line tracking, the robot adheres to its path. It uses image processing to detect green squares, using these as indicators for directional decisions—forward, left, or right—based on their size and location. Additionally, the robot can identify red stop lines and manage intersections, leveraging the OpenCV library for tasks like contour detection, improving its interaction with the environment.
        
        The robot's design emphasizes decision-making and robustness under conditions, ensuring adaptability to course changes and obstacles. Real-time processing enables the robot to make navigational decisions, a feature for handling course layouts. Through testing in lighting and environmental conditions, the system's reliability has been validated. This integration of technologies demonstrates the robot's ability to navigate courses with precision, highlighting the potential of combining computer vision with real-time processing in applications.
        `,
        image: './project41.jpg',
        youtubelink: "",
        githublink: "https://github.com/Kelo2000/Line-Following-Robot-"
        
    },
]
    return( 
        
        <div id="projects">
            <div className="MainContent">
                    <h1 className="Heading">
                        My Projects
                    </h1>
                    <div className="Myprojects">
                        {projects.map(project=>{
                            return(
                                    <MyCard 
                                    key={project.id}
                                    title={project.title}
                                    description={project.description}
                                    image={project.image}
                                    youtubelink={project.youtubelink}
                                    githublink={project.githublink}
                                    />
                                );
                                
                            
                            })
                        }
                    </div>

            </div>
           
         </div>
        )

}

