import React from "react";

import "./footer.css"

function Footer() {
	return (
		<>
		<div className="MyFooter"> 
			<div className="Footertext">
				<h5>©2024 Lukelo Luoga</h5>
			</div>
		</div>
	  </>

	);
}

export default Footer;