
import React, { useRef } from "react";
import emailjs from '@emailjs/browser';

import "./contacts.css";

export default function Contacts(){
    const form = useRef();
    const SendEmail=(e)=>{
         e.preventDefault();

         emailjs.sendForm('mywebsite_email_kelo0001', 'mywebsite_kelo0001', form.current, 'A4OCgtRvZsnbwimHb')
           .then((result) => 
           {
               console.log(result.text);
           }, (error) => {
               console.log(error.text);
           });
           e.target.reset();

    }

    return( 
        <div id="contacts">
        <div className="MainContent">
             <h1> Contact Me</h1>
             <div className="AllContacts">
                <div className="ContactDetails">
                    <div>
                    <h5>Get in Touch</h5>
                        <p>Email: lukeloluoga34@gmail.com</p>
                        <p>Phone Number: +971501078390</p>
                    </div>
                    <div className="SocialLinks">
                        <a href="https://www.linkedin.com/in/lukelo-luoga-9bb941167/">
                            <img src="/icons8-linkedin-48.svg"  alt="linkedln profile"/>
                        </a>

                        <a href="https://github.com/Kelo2000">
                            <img src="/icons8-github.svg"  alt="github profile"/>
                        </a>
                    </div>

                </div>

                <div className="SendEmail">
                    <form ref={form} onSubmit={SendEmail}>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Full Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="user_name" 
                            placeholder="Your Name"
                            style={{ backgroundColor: "#cbcbcb" }}
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Email</label>
                        <input 
                            type="email"
                            className="form-control" 
                            name="user_email"
                            placeholder="Your Email"
                            style={{ backgroundColor: "#cbcbcb" }}
                            />
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Subject</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="email_subject"
                            placeholder="Subject"
                            style={{ backgroundColor: "#cbcbcb" }}
                        />
                    </div>
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                        <textarea 
                            className="form-control" 
                            style={{ backgroundColor: "#cbcbcb" }}
                            name="email_description"
                            rows="3"
                        />
                    </div>
                    <div class="mb-3">
                        <button type="submit" class="mysendbtn btn-primary mb-3">Send Message</button>
                    </div>
                    </form>
                </div>
            </div>

         </div>
    </div>
        )

}
