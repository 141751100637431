import React from "react";

import "./resume.css";
import {ReactComponent as WorkIcon} from "./work.svg"
import {ReactComponent as UniversityIcon} from "./university.svg"

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


export default function Resume() {

    const timelineElements=[
        {id: 0,
            title:"Senior Capstone-NYUAD",
            location: "Abu Dhabi, UAE",
            description: "Developing an optimization framework to reduce computational complexity and memory footprint of Multimodal Large Language Models (MLLMs) using Python and Pytorch",
            date: " August 2023 - Present",
            buttontext: "View Projects",
            icon: "work"
        },


            {id: 1,
                title:"eBRAIN Lab Research Assistant",
                location: "Abu Dhabi, UAE",
                description: "Implementing and Analyzing Machine Learning Backdoor attacks and defences using Python and Pytorch",
                date: " August 2023 - Present",
                buttontext: "View Projects",
                icon: "work"
            },

            {id: 2,
                title:"C2SMART Research Assistant",
                location: "New York, USA",
                description: "Used Python, YoloV8, StrongSORT, PyTorch, Social GANs for traffic simulation, pedestrian safety, and vehicle tracking",
                date: " Jun 2022 - August 2023",
                buttontext: "View Projects",
                icon: "work"
            },

            {id: 3,
                title:"NYU Tandon School of Engineering ",
                location: "New York, USA",
                description: "Bachelor of Science in Computer Engineering",
                date: " January 2023 - May 2023",
                buttontext: "College Projects",
                icon: "university"
            },

            {id: 4,
                title:"New York University Abu Dhabi",
                location: "Abu Dhabi, UAE",
                description: "Bachelor of Science in Computer Engineering",
                date: " August 2020 - May 2024",
                buttontext: "College Projects",
                icon: "university"
            },
        ]

    let workIconStyles={background: "#7BAFD4"}
    let universityIconStyles={background: "#7BAFD4"}
    return( 
        <div  id="resume">
        <div className="MainContent">
            <h1>My experiences</h1>
            <VerticalTimeline >
                {timelineElements.map(element=>{
                    let isWorkIcon=element.icon==="work";
                        return(
                            <VerticalTimelineElement
                            contentStyle={{ background: '#cbcbcb'}}
                            key={element.key}
                            date={element.date}
                            dateClassName="date"
                            iconStyle={isWorkIcon ? workIconStyles: universityIconStyles}
                            icon={isWorkIcon ? <WorkIcon/>: <UniversityIcon/>}
                            >
                            <h3 className="vertical-timeline-element-title">{element.title}</h3>
                            <h5 className="vertical-timeline-element-subtitle">{element.location}</h5>
                            <p id="description">{element.description}</p>
                            </VerticalTimelineElement>
                         
                        );
                    })}
            </VerticalTimeline>
         </div>
         </div>
        )

}
