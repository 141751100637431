import React,{ useState } from "react";

import "./home.css";
import { Modal } from "react-bootstrap";

const ResumeModal = ({ show, onHide }) => {
    return (
        <Modal Modal show={show} 
        onHide={(e) => {e && e.stopPropagation(); onHide();}}
        centered onClick={(e) => e.stopPropagation()}>
        <Modal.Header closeButton onClick={onHide} style={{ backgroundColor: "#cbcbcb" }}>
          <Modal.Title>Lukelo Luoga Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#cbcbcb" }}>
          <div className="modal-scrollable-content-custom">
                <iframe 
                    src="./Lukelo Luoga-CV.pdf"
                    style={{ width: '100%', height: '90%', border: 'none' }}
                    title="Lukelo Luoga Resume"
                    />
            <button onClick={onHide} className="mycvbtn btn-primary">
             Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };


export default function Home() {
  const [show, setShowModal] = useState(false);

  const handleShowModal = (event) => {
        event.stopPropagation(); // Prevent click from bubbling to elements behind the modal
        setShowModal(true);
        };
      
    const handleCloseModal = (event) => {
        event.stopPropagation();
        setShowModal(false);
    };
   return( 
    <section id="home">
    <div id="home">
   <div className="MainContent">
    <div className="Allcomp">
        <div className="Alltext">
                <div className="Name">
                    <p>
                        Hi,I'm Lukelo!
                    </p>
                </div>
                
                <div className="intro">
                    <p>
                    A tech enthusiast and computer engineering student from NYUAD who gets excited about the 
                    magic of machine learning and the thrill of autonomous vehicles.
                    I'm on a mission to mesh technology with real-world impact, 
                    and I'm always on the lookout for projects that aim for the stars – 
                    or at least make life down here a bit brighter.
                    Let's connect and create something meaningful!
                     
                     </p>
                </div>

            <div className="SocialMedia">
                <h5>Find Me!</h5>
                <div className="SocialLinks">
                    <a href="https://www.linkedin.com/in/lukelo-luoga-9bb941167/">
                        <img src="/icons8-linkedin-48.svg"  alt="linkedln profile"/>
                    </a>

                    <a href="https://github.com/Kelo2000">
                        <img src="/icons8-github.svg"  alt="github profile"/>
                    </a>

                </div>

                <button onClick={handleShowModal} className="mycvbtn btn-primary">
                     See my CV
                </button>
                    <ResumeModal 
                        show={show}
                        onHide={handleCloseModal}
                    />


            </div>

        </div>

        <div className="MyPicture">
            <img src="/headshot.jpg" alt="MyPicture"/>
        </div>
    </div>
        
    </div>

    </div>
    </section>
   );
}
